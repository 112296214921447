import { SET_SELECTED_CHAIN } from '../actions/actions';

export default function chainFinder(state = {feed: ''}, action) {
    switch(action.type) {
        case SET_SELECTED_CHAIN:
            return { feed: action.feed }
        default:
            return state;
    }
}
