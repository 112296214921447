import Keycloak from 'keycloak-js';

export const getBackendUrl = (hostName?: string) => {
    let backendUrl = null;
    if(typeof window !== 'undefined') {
        const hostName = window.location.hostname.split('.')[0]
        if(hostName === 'localhost') {
            backendUrl = 'http://localhost:9000';
        } else if(hostName === 'docs-stage') {
            backendUrl = 'https://docs-backend-stage.infrontfinance.com';
        } else if(hostName === 'docs') {
            backendUrl = 'https://docs-backend.infrontfinance.com'
        } else backendUrl = 'https://docs-backend.infrontfinance.com'
    }
    return backendUrl;
};
export const getWordpressUrl = (hostName?: string) => {
    let backendUrl = null;
    if(typeof window !== 'undefined') {
        const hostName = window.location.hostname.split('.')[0]
        if(hostName === 'localhost') {
            backendUrl =  'https://docadmintest.infrontservices.com/graphql';
        } else if(hostName === 'docs-stage') {
            backendUrl = 'https://docadmintest.infrontservices.com/graphql';
        } else if(hostName === 'docs') {
            backendUrl = 'https://docs-admin.infrontfinance.com/graphql'
        } else backendUrl = 'https://docadmintest.infrontservices.com/graphql'
    }
    return backendUrl;
};

export const getWordpressDomain = (hostName?: string) => {
    let backendUrl = null;
    if(typeof window !== 'undefined') {
        const hostName = window.location.hostname.split('.')[0]
        if(hostName === 'localhost') {
            backendUrl = 'https://docs-admin.infrontfinance.com/';
        } else if(hostName === 'docs-stage') {
            backendUrl = 'https://docs-admin.infrontfinance.com/';
        } else if(hostName === 'docs') {
            backendUrl = 'https://docs-admin.infrontfinance.com/'
        } else backendUrl = 'https://docadmintest.infrontservices.com/'
    }
    return backendUrl;
};
export const getKeycloakConfig = (hostName?: string) => {
    return {
        url: process.env.KC_SERVER,
        realm: 'infront',
        clientId: 'wtk-docsite-client',
    } as Keycloak.KeycloakConfig;
};


export const pipeInfrontOpts = <Toolkit, Options>( initToolkitOptions: <Toolkit>() => Options, ...fns: Array<(a: Options) => Options>) => {
    //@ts-ignore
    const infrontExists = typeof Infront !== 'undefined' ? Infront : null;
    if(infrontExists) {
        const piped = fns.reduce(
            (prevFn, nextFn) => (value: Options) => {
                return nextFn(prevFn(value))
            },
            value => value
        );
        return piped(initToolkitOptions());
    }
};

export const isOdd = (num: number) => { return num % 2;}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
  

export const jsonParse = (value: string) => {
    if(!value) return
    try {
        return JSON.parse(value)
    } catch (error) {
        return undefined
    }
}

export function utf8_to_b64(str) {
    return isBrowser && window.btoa(unescape(encodeURIComponent(str)));
}

export function b64_to_utf8(str) {
    return isBrowser && decodeURIComponent(escape(window.atob(str)));
}

export const isBrowser = typeof window !== 'undefined'

export const translateDatabaseShareItems = (text) => {
    const snippet = text
    if(text) {
        const code = snippet?.replace(/[“”‘’]/g,'"')
        const html = code;
        const div = document.createElement("div");
        div.innerHTML = html;
        const text = div.textContent.toString() || div.innerText.toString() || "";
        try {
            const parts = text?.split('|?');
            parts.pop()
            return parts.map(part => b64_to_utf8(part))
        } catch (error) {
            /* console.log(error)
            console.log(text) */
        }
    }

}

export const debounce = (func: (args?: any) => void, wait: number, immediate: boolean) => {
    let timeout;
    return function() {
        const context = this;
        const args = arguments;
        const later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
  