
import React, { Component, useState } from "react"
import { useEffect } from "react"
import { LoaderComponent } from './components/loader';


const Apollo = (props) => {
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        const restoreCache = async () => {
            try {
                await props.persistor.restore();
                setLoaded(true)
            } catch (error) {
              console.error("Error restoring Apollo cache", error)
            }
    
        }
        restoreCache()
    }, [])
    if (!loaded) return <LoaderComponent absolute></LoaderComponent>
    else return <>{props.children}</>
}
export default Apollo