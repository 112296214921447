import { SET_RETURN_URL } from '../actions/actions';

export default function returnURL(state = {url: ''}, action) {
    switch(action.type) {
        case SET_RETURN_URL:
            return { url: action.url }
        default:
            return state;
    }
}
