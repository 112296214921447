import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet"
import { useGlobal } from 'reactn';
import { isBrowser } from './utils/utils';
import { LoaderComponent } from './components/loader';

export const InfrontProvider = (props) => {
    const urlParts = isBrowser && window.location.search?.split('?')[1]?.split('=')
    let hasCodeQuery = false
    if(urlParts && urlParts[0] === "quer") hasCodeQuery = true
    const toolkitVersion = isBrowser && localStorage.getItem('wtk-version')?.trim()

    const [globalWtkVersion, setGlobalWtkVersion] = useGlobal('globalWtkVersion')
    const [SDKVersion, setSDKVersion] = useGlobal('SDKVersion')
    useEffect(() => {
        setGlobalWtkVersion(toolkitVersion || 'Master')
    }, [toolkitVersion])

    const [cwfLoaded, setCwfLoaded] = useState(typeof window !== 'undefined' && typeof window.Infront !== 'undefined')
    const [infinancialsLoaded, setInfinancialsLoaded] = useState(typeof window !== 'undefined' && typeof window.Infinancials !== 'undefined')
    const [uiLoaded, setUILoaded] = useState(typeof window !== 'undefined' && typeof window.Infront?.UI !== 'undefined')


    let cwf = "https://wtk.infrontservices.com/js/CommonFramework-latest.min.js"
    let ui = "https://wtk.infrontservices.com/js/InfrontUI-latest.min.js"
    const visuals = 'https://wtk.infrontservices.com/js/VisualUI-latest.min.js'
    if(toolkitVersion) {
        if(toolkitVersion && toolkitVersion.toLowerCase() === 'master') {
            ui = 'https://wtk.infrontservices.com/js/InfrontUI-latest.min.js'
            cwf = 'https://wtk.infrontservices.com/js/CommonFramework-latest.min.js'
        }  else if(toolkitVersion && toolkitVersion.toLowerCase() === 'beta') {
            cwf = 'https://wtk.infrontservices.com/beta/JS/CommonFramework-latest.min.js'
            ui = 'https://wtk.infrontservices.com/beta/JS/InfrontUI-latest.min.js'
        } else if(toolkitVersion?.trim()?.toLowerCase() === "local") {
            const localToolkitVersionPort = '8081'
            cwf =   `http://localhost:${localToolkitVersionPort}/CommonWebFramework/output/js/CommonFramework-latest.c.js`
            ui =  `http://localhost:${localToolkitVersionPort}/InfrontUI/js/InfrontUI-latest.c.js`
        } else {
            let uiToCwf =  toolkitVersion.split('.')
            uiToCwf[0] = (parseInt(uiToCwf[0]) -1).toString()
            const ver = uiToCwf.join('.')
            ui = toolkitVersion ? 'https://wtk.infrontservices.com/js/InfrontUI-'+ toolkitVersion + '.min.js' : 'https://wtk.infrontservices.com/js/InfrontUI-latest.min.js'
            cwf = toolkitVersion ?  'https://wtk.infrontservices.com/js/CommonFramework-' + ver + '.min.js' : 'https://wtk.infrontservices.com/js/CommonFramework-latest.min.js'
        }
    }


    const handleChangeClientState = (newState, addedTags) => {
        if (addedTags && addedTags.scriptTags) {
            addedTags.scriptTags.forEach((foundScript) => {
                switch(foundScript.src) {
                    case cwf: {
                        foundScript.addEventListener('load', () =>  setCwfLoaded(true), { once: true });
                        foundScript.addEventListener('error', () => {
                            isBrowser && console.warn('Script ' + cwf + ' ' + 'cannot be found')
                            setTimeout(() => {setCwfLoaded(true)}, 500)
                        }, { once: true });
                        break;
                    }
                    case ui : {
                        foundScript.addEventListener('load', () => {
                            const interval = setInterval(() => {
                                if(isBrowser && window.Infront.UI !== undefined) {
                                    setUILoaded(true)
                                    clearInterval(interval)
                                }
                            }, 100)

                        }, { once: true });
                        foundScript.addEventListener('error', () => {
                            isBrowser && console.warn('Script ' + ui + ' ' + 'cannot be found')
                            setTimeout(() => {setUILoaded(true)}, 500)
                            
                        }, { once: true });
                        break;
                    }
                    case visuals: {
                        foundScript.addEventListener('load', () => setInfinancialsLoaded(true), { once: true });
                        foundScript.addEventListener('error', () => {
                            isBrowser && console.warn('Script ' + ui + ' ' + 'cannot be found')
                            setTimeout(() => {setUILoaded(true)}, 500)
                            
                        }, { once: true });
                        break
                    }
                }
            } )
        }
    }
    const codeUrl = (hasCodeQuery && urlParts) ? '?' + urlParts[0] + '=' + urlParts[1] : ''
    if(uiLoaded && cwfLoaded && infinancialsLoaded) window.history.pushState(
        {}, 
        document.title, 
        window.location.href
    )
    return (
        <>
            <Helmet onChangeClientState={handleChangeClientState}>
                <meta charSet="utf-8" />
                <title>WTK Docs</title>
                <script type="text/javascript" id="common-framework" src={cwf}/>,
                {cwfLoaded && <script type="text/javascript" id="infront-ui" src={ui} />},
                {uiLoaded && cwfLoaded ? <script type="text/javascript" src={visuals}></script> : <script type="text/javascript" src=''></script>}
            </Helmet>
            {(uiLoaded && cwfLoaded && infinancialsLoaded) ? props.children : <LoaderComponent absolute></LoaderComponent>}
        </>
    )
}
