import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { CachePersistor } from '@graphqlheroes/apollo-cache-persist';
import { LocalStorageWrapper } from 'apollo3-cache-persist';
import fetch from 'isomorphic-fetch';

import { getWordpressUrl, isBrowser } from '../utils/utils';

export const cache = new InMemoryCache();

let auth = ''
if(isBrowser) auth = 'Basic ' + btoa( 'gatsby:pI2Zoz79NvvZBQuNcJesR*NQ' )
const url = isBrowser ? getWordpressUrl() : ''
export const link = new HttpLink({
    fetch,
    uri: url,
    headers: {
      'Authorization' : auth
    }
})
export const client = new ApolloClient({
  cache: cache,
  link,

  defaultOptions: {
    query: {
      errorPolicy: 'ignore',
    },
  },
});

export const linkTestServer = new HttpLink({
  fetch,
  uri: 'https://docadmintest.infrontservices.com/graphql',
  headers: {
    'Authorization' : auth
  }
})
export const clientTestServer = new ApolloClient({
cache: cache,
link: linkTestServer,

defaultOptions: {
  query: {
    errorPolicy: 'ignore',
  },
},
});


export const persistor = new CachePersistor({
  //@ts-ignore
  cache,
  storage: new LocalStorageWrapper(typeof window !== 'undefined' && window.localStorage),
  maxSize: false,
  debug: process.env.NODE_ENV !== "production",
})
  