import React, { useState } from 'react'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './src/reducers/rootReducer'
import theme from './src/theme'
import GlobalStyles from './src/styles/globalStyles'
import { ApolloProvider } from '@apollo/client';
import { cache, client, persistor } from './src/apollo/client';
import { ThemeProvider } from "styled-components";
import { createTheme, ThemeProvider as MUIThemeProvider } from '@material-ui/core'
import { InitProvider } from './src/initProvider'
import Apollo from './src/apolloWrapperPovider'
import { InfrontProvider } from './src/infrontProvider'

import { ReactKeycloakProvider } from '@react-keycloak/web'
import { getKeycloakConfig, isBrowser } from './src/utils/utils'
let Keycloak = typeof window !== 'undefined' ? require('keycloak-js') : null

if (typeof window === 'undefined') {

    require("localstorage-polyfill");
}

let keycloak = typeof window !== 'undefined' ? Keycloak(getKeycloakConfig()) : null;

const MUITheme = createTheme({
    overrides: {
        MuiOutlinedInput: {
            root: {
                '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                    borderColor: 'rgba(0,0,0,0.23)',
                },

            },
        },
        MuiInput: {
            input: {
                "&::placeholder": {
                    color: "red"
                },
                color: "red", // if you also want to change the color of the input, this is the prop you'd use
            }
        },
        MuiSvgIcon: {
            root: {
                fontSize: '19px'
            }

        }
    },
    palette: {
        type: 'light',
        primary: {
            main: '#00374F',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#45A2E3',
            contrastText: '#FFFFFF'
        },


    },
});

export const store = createStore(rootReducer)
const infrontProvider = (props) => {
    return (
        <>
            <ReactKeycloakProvider
                authClient={keycloak}
                initOptions={{
                    onLoad: "login-required",
                    silentCheckSsoRedirectUri: isBrowser && window.location.origin + '/silent-check-sso.html',
                    checkLoginIframe: false
                }}
                >
                <InfrontProvider>

                    <InitProvider>
                        <Apollo persistor={persistor} cache={cache}>
                            <ApolloProvider client={client}>
                                <ThemeProvider theme={theme}>
                                    <MUIThemeProvider theme={MUITheme}>
                                        <Provider store={store}>
                                            {props.element}
                                            <GlobalStyles />
                                        </Provider>
                                    </MUIThemeProvider>
                                </ThemeProvider>
                            </ApolloProvider>
                        </Apollo>
                    </InitProvider>

                </InfrontProvider>
            </ReactKeycloakProvider>
        </>

)
}
export default infrontProvider;



