const theme = {
    colors: {
        elephant: '#12374D',
        wildSand: '#F6F6F6',
        white: '#FFFFFF',
        polar: '#DDEFF8',
        pictonBlue: '#45A2E3',
        silverChalice: '#9E9E9E',
        alto: '#D6D6D6',
        mineShaft: '#3E3E3E',
        prussianBlue: '#00374F',
        baliHai: '#8AA3AE',
        darkerBaliHai: '#8ffa3ae',
        lighterBaliHai: '#c4d1d6',
        sidemenuSelectedBackgroundColor: '#D9EFF9',
        sidemenuSelectedTextColor: '#00A3E9',
        textColor:  '#000A0D',
        body: {
            backgroundColor: '#F0F0F3',
        },
        select: {
            backgroundColor: '#F0F0F3',
            textColor: '#000A0D',
        },
        buttons: {
            run: {
                backgroundColor: '#CBF5D5',
                borderColor: '#31DE59',
                textColor: '#31DE59',
                fillColor: '#31DE59',
            },
            save: {
                backgroundColor: '#CBE6F5',
                borderColor: '#01A4EA',
                textColor: '#01A4EA',
                fillColor: '#01A4EA',
            },
            share: {
                backgroundColor: '#CBE6F5',
                borderColor: '#01A4EA',
                textColor: '#01A4EA',
                fillColor: '#01A4EA',
            },
            delete: {
                backgroundColor: '#FFC9C1',
                borderColor: '#FF2300',
                textColor: '#FF2300',
                fillColor: '#FF2300',
            },
            action: {
                backgroundColor: '#001721',
                textColor: '#CBE6F5',
                fillColor: '#CBE6F5',
            }
        },
        notifications: {
            sandboxNotifications: {
                backgroundColor: '#dc3545'
            }
        },
        text: {
            secondary: '#989898'
        }
    },
    fonts: {
        roboto: 'Roboto',
        sansSerif: 'sans-serif'
    },
    fontSizes: {
        small: "1em",
        medium: "2em",
        large: "3em"
    }
}

export default theme