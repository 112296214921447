import { navigate } from 'gatsby';
import { setGlobal } from 'reactn';

import { store } from '../../wrap-with-provider';
import { setInfrontReady } from '../actions/actions';
import { getBackendUrl } from '../utils/utils';


const isBrowser = typeof window !== "undefined"

const auth0 = isBrowser ? require('auth0-js') : null;
export const InfrontReady = (event) => {
  setGlobal({
    infront: infront
  })
  store.dispatch(setInfrontReady(infront))
} 



const auth = isBrowser
  ? new auth0.WebAuth({
    domain: 'apitest.infrontservices.com/id/connect/',
    clientID: 'js.simple',
    redirectUri: process.env.AUTH_REDIRECT_TO,
    responseType: "id_token token",
    scope: "read openid",
    }) as auth0.WebAuth
  : {} as auth0.WebAuth

const tokens = {
  accessToken: '',
  idToken: '',
  expiresAt: 0,
}

export const isAuthenticated = () => {
  if (!isBrowser) {
    return;
  }

  try {
    
    const token =JSON.parse(localStorage.getItem('token'))
    if(!token) {
      localStorage.removeItem('isLoggedIn');
      return false 
    } else if(token && token.expiresAt < Date.now()) {
      localStorage.removeItem('token');

      auth.renewAuth({}, setSession()) 
    } else if(localStorage.getItem("isLoggedIn") === "true") {
      if(token && !infront) InitializeToolkit(token.token,token.token)
      return true;
    } else {
      localStorage.removeItem('isLoggedIn');
      auth.parseHash(setSession())
    }
  } catch (error) {
    (error)
  } 

}

export const login = () => {
  if (!isBrowser) {
    return
  }

  auth.authorize()
}

const processAuthResult = authResult => {
  let expiresAt = (authResult.expires_in  || authResult.expiresIn) * 1000 + new Date().getTime()
  tokens.accessToken = authResult.access_token || authResult.accessToken 
  tokens.idToken = authResult.id_token || authResult.idToken
  tokens.expiresAt = expiresAt
  InitializeToolkit(tokens.accessToken,  tokens.accessToken)
  const sessionObject = {
    expiresAt: tokens.expiresAt,
    token: tokens.accessToken,
    id_token: tokens.idToken
  }
  localStorage.setItem("isLoggedIn", "true")
  localStorage.setItem('token', JSON.stringify(sessionObject))
  window.location.href = localStorage.getItem("returnUrl")
}

const setSession = (cb = () => {}) => (err, authResult) => {
  if (err) {
    navigate("/")
    cb()
    return
  }
  if (authResult) {
    processAuthResult(authResult)

    cb()
  }
}


const getHashParams = () => {

  const hashParams = {};
  let e,
      a = /\+/g,  // Regex for replacing addition symbol with a space
      r = /([^&;=]+)=?([^&;]*)/g,
      d = function (s) { return decodeURIComponent(s.replace(a, " ")); },
      q = window.location.hash.substring(1);

  while (e = r.exec(q))
     hashParams[d(e[1])] = d(e[2]);

  return hashParams;
}
export const handleAuthentication = () => {
  if (!isBrowser) {
    return;
  }
  processAuthResult(getHashParams())
}

export let infront=null;
let visual = null;

export const InitializeToolkit = async (token: string | null, refreshToken: string) => {
  if(isBrowser && window.Infront && token) {
    //debugger
    const res = await fetch(getBackendUrl() + '/api/login-url')
    const loginUrl = await res.text()
    const opts = {
      token_type: "JWT",
      signed_token: token,
      useDefaultStateStorage: true,
/*       user_id: 'Zahirovic',
      password: 'Infront321', */
      // loginUrls: [loginUrl],
      language: "en",
  } as Infront.InfrontUIOptions;
    opts.visualWidgetAccess = true;
    opts['visualStatelessEndpoint'] = true;
    infront = typeof Infront.UI !== "undefined" && new Infront.UI(opts);
    visual = infront && typeof  Infinancials.UI !== "undefined" && new Infinancials.UI(opts, infront.getModel())
    window.infront = infront;
    window.visual = visual;
    typeof infront?.registerEventObserver === "function" && infront?.registerEventObserver("onReady", (event) => {
      setGlobal({
        infront: infront
      })
      store.dispatch(setInfrontReady(infront))
    });
    let sdk = null
    sdk = isBrowser && window?.InfrontSDK && new InfrontSDK.SDK({
      signedToken: token,
      onReady: event => {
        
      }
    });
    window.sdk = sdk
    typeof infront?.registerEventObserver === "function" && infront?.registerEventObserver("onDisconnect", function (event) { console.error('disconnect') });
    typeof infront?.registerEventObserver === "function" && infront?.registerEventObserver("onLoginFailed", function (event) { 
      console.warn(event)
      if(event) {

      }


    });
    try {
      infront.init();
    } catch (error) {
      console.log(error)
    }

  
    return infront;
  }
}
