import React, { Fragment } from "react";



type LoaderComponentProps = {
  width?: number
  absolute?: boolean
}

export const LoaderComponent = ({width, absolute}: LoaderComponentProps) => (
    <Fragment>
      <div style={{ position: absolute ? 'absolute' : 'relative', left: absolute && 0,height: '100%', width: width ? width + 'px' : '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: "16px 0"}}>
        <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
    </Fragment>
)
