
import { createGlobalStyle } from 'styled-components'
import 'react-perfect-scrollbar/dist/css/styles.css';

const GlobalStyles = createGlobalStyle`

    @font-face {
        font-family: 'Material Icons';
        font-style: normal;
        font-weight: 400;
        src: url('https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2') format('woff2');
    }   
    /* * {
        font-family: 'Roboto', sans-serif;
    } */

    html,body,h1,h2,h3,h4,h5,p,div,pre {
        font-family: 'Roboto', sans-serif;
    }
    html {
        overflow-y: visible;
    }
    body {
        background-color:  ${props => props.theme.colors.body.backgroundColor};
       /*  &::-webkit-scrollbar {
             display: none;
        } */
        height: 100%;
        overflow-y: visible;
        overflow-x: hidden;
    }
    /*Scrollbar overrides*/

    .thin-scrollbar {
        scrollbar-width: thin;
        scrollbar-color: #C3C3CC;
    }
    .no-scrollbar {
        scrollbar-width: none;
        &::-webkit-scrollbar {
             display: none;
        }
    }
    .thin-scrollbar::-webkit-scrollbar {
        width: 5px;
    }
    .thin-scrollbar::-webkit-scrollbar-track {
        background: #F0F0F3; 
    }       
    .thin-scrollbar::-webkit-scrollbar-thumb {
        background: #C3C3CC; 
    }
    .elipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .MuiAutocomplete-listbox {
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background: #F0F0F3; 
        }       
        &::-webkit-scrollbar-thumb {
            background: #C3C3CC; 
        }
        scrollbar-width: thin;
        scrollbar-color: #C3C3CC;
        max-height: 300px!important;
        padding: 16px;
    }
    .material-icons {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;  /* Preferred icon size */
        display: inline-block;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;

        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;

        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;

        /* Support for IE. */
        font-feature-settings: 'liga';
    }
    .decorationsOverviewRuler {
        display: none;
    }
    /* Common */
    body, html, h1, h2, h3, h4, h5, p, a {
        font-family: ${props => props.theme.fonts.roboto};
        color: 'white'!important;

    }
    body {
        font-size: 0.875rem;
    }

    h1 {
        font-weight: 400 !important;
        font-size: 2rem !important;
    }
    
    h2 {
        font-size: 1.25rem !important;
    }


    ul {
        margin: 0;
        padding: 0;
    }

    mark {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    /* Utils */
    .position-relative {
        position: relative;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .capitalize {
        text-transform: capitalize;
    }

    /* Header */
    .navigation {
        font-size: 16px;

        &__main {
            background: white;
            line-height: 60px;
            overflow:hidden;
            height:auto;
            max-height:600px;

            a {
                color: $fontgrey;
                font-weight: 500;
            }

            &--active {
                position: relative;
                &:after {
                    content: " ";
                    left: calc(50% - 8px);
                    bottom: -21px;
                    position: absolute;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-bottom: 8px solid $secondary;
                }
            }
        }
        &__secondary {
            background: $secondary;
            padding:  30px;
            h2, a {
                color: white;
            }
            h2 {
                font-weight: 300;;
            }
            a {
                opacity: 0.5 !important;
            }
        }
    }

    .search {
        &__container {
            position: relative;
            margin: 0;
            width: 100%;
        }

        &__input {
            height: 30px;
            border: 1px solid $fontgrey;
            padding: 2px 10px;
            position: relative;
            -webkit-transition: all .5s;
            -moz-transition: all .5s;
            transition: all .5s;
            border: none;
            border-radius: 3px;
            border: 1px solid ${props => props.theme.colors.alto};
            box-shadow: 0px 0px 10px -4px ${props => props.theme.colors.alto};

            &:placeholder-shown{
                font-family: ${props => props.theme.fonts.roboto};
                font-size: 12px;
            }

            &:focus + .search__results  {
                visibility: visible;
                opacity: 1;
                transition-delay: .5s;
            }

        }
        &__clear-button {

            &:focus .search__input {
                width: 800px !important;
                border: 3px solid red;
            }

            &:focus .search__results{
                visibility: visible;    
                background: red !important;
            }

        }
        &__icon {
            position: absolute;
            z-index: 2;
            top: 4px;
            right: 5px;   
            color: ${props => props.theme.colors.alto}; 
        }

        &__results {
            background-color: white;
            margin-left: 0 !important;
            list-style: none;
            width: 100%;
            visibility: hidden;
            position: absolute;
            z-index: 12;

            padding: 12px;

            margin-top: -15px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border: 1px solid #9C9C9C;
            border-top: 0;
            line-height: 30px;
            &:active {
                visibility: visible;
            }

            a, li, p{
                padding: 0;
            }

        }

    }
    .active {
        background: #9C9C9C !important;
    }

    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }
    /* Sidebar */

    /* Page */

    /* Widget */
    .widget {

    }

    .hljs {
        padding: .375rem 0.75rem !important;
    }

    .options {
        margin-left: -15px;
        width: calc(100% + 30px);
        &__item {
            margin-bottom: 2%;
            background: #F5F5F5;
            padding: 15px;  
            border: 12px solid #fff !important;  
            margin-bottom: 0;
            border-bottom: 15px solid white;  

          }
        &__label {
            float:right;
            background: #9C9C9C;
            color: #fff;
            padding: 2px 7px;
            border-radius: 5px;
            font-size: 12px;
            &--red {
                background: #AC4D4D;
            }
            &:first-of-type {
                margin-left: 5px;
            }
        }
        &__tag {
            background: #9C9C9C;
            color: #fff;
            padding: 2px 7px;
            border-radius: 5px;
            font-size: 12px;
            margin-right: 8px;
            text-transform: capitalize;
            &--red {
                background: #AC4D4D;
            }
        }

        &__inner {
            float: left;
            clear: both;
            width: 100%;
            display: block;
            p, ul {
                float: left;
                clear: both;
            }
            p {
                padding-top: 10px;
            }
            ul {
                margin-left: 20px;
            }
        }

        &__property {
            padding: 4px 0;
            width: 100%;
            float: left;
            clear: both;
            &--navigate {
                color: blue;
            }
        }

        &__heading {
            float:left;
        }
        &__subheading {
            float:left;
            margin:0;
        }
      }


    /* Sandbox container */
    .sandbox {
        &__container {
            border: solid 1px rgb(221, 221, 221);
        }

        &__html {
            border-bottom: solid 1px rgb(221, 221, 221);
            padding-left: 0 !important;

        }

        &__js {
            border-bottom: solid 1px rgb(221, 221, 221);
            border-left: solid 1px rgb(221, 221, 221);
            padding-left: 0 !important;

        }

        &__css {
            border-left: solid 1px rgb(221, 221, 221);
            padding-left: 0 !important;
        }
    }

    /* Helpers */

    .no-padding {
        padding: 0 !important;
    }

    .no-margin {
        margin: 0 !important;
    }

    .flex-container {
        float: left;
        width: 33%;
        .column {
            border: 1px solid black;
            margin: 5px;
            padding: 5px;
        }
    }

    .chainfinder {
        &__group {
            position: relative;
        }
    }

    .dotted {
        border: 1px dotted grey;
    }

    /*FOOTER*/

    .footer {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 48px 36px;
        color: #fff;
        background: #424242;
        width: 100%;
        min-height: 60px;
    }
    .footer .social-media-links img {
        width: 24px;
        margin: 0 7px;
    }

    .footer > p > a, .footer > p > span > a {
        font-weight: 700;
        color: #076e8d;
    }
    .footer > p > a:hover, .footer > p > span > a:hover {
        color: #00b0db !important;
        text-decoration: underline;
    }

    /*TOOLKIT OVERRIDES*/
    .cell-input {
        height: 40px;
        width: 300px;
        border: 1px solid $fontgrey;
        padding: 2px 10px;
        position: relative;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        transition: all .5s;
        border: none;
        border-radius: 3px;
        border: 1px solid ${props => props.theme.colors.alto};
        box-shadow: 0px 0px 10px -4px ${props => props.theme.colors.alto};

    }

    /*ANT OVERRIDES*/
    .ant-menu-item-selected {
        border-radius: 10px;
        width: 95%!important;
        margin: auto;
        color: ${props => props.theme.colors.pictonBlue};
    }
    .ant-menu-submenu-title {
        border-top: 1px solid lightgrey;
        margin: 0;
        &:last-child {
            border-bottom: 1px solid lightgrey;
        }
    }
    .ant-menu-inline .ant-menu-submenu-title{
        margin: 0;
    }
    .ant-layout-content > div {
        padding: 22px 40px 40px !important
    }
    .ant-breadcrumb {
        font-size: 13px !important;
        font-weight: bold;
    }
    .ant-menu-item {
        font-weight: bold;
    }
    .ant-menu-item-selected,
    .ant-menu-item-selected > a,
    .ant-menu-item-selected > a:hover {
        color: #01a4ea;
    }
    /*Material UI OVERIDES*/
    .MuiButton-root{
        color: 'white'!important
    }

    /*Code Mirror Overiddes*/
    .code-highlight{
        font-size: 14px;
    }
    pre {
        font-size: 14px;
    }
    .monaco-editor {
        height: 100%!important;
        border-radius: 4px;
    }

    /*Monaco overrides*/
    .overflow-guard {
        border-radius: 4px;
    }
    .margin {
        padding: 12px 0;
    }
    .overflow-guard {
        padding: 12px 0;
    }
    .monaco-editor > .view-line {
        font-family: Consolas, "Courier New", monospace!important;
    };
    .monaco-editor .view-line > span {
        font-family: Consolas, "Courier New", monospace!important;
    };

    .monaco-editor .margin {
        background-color: #000A0D!important;
    }
    .monaco-editor, .monaco-editor-background, .monaco-editor .inputarea.ime-input {
        background-color: #000A0D!important;
    }


    /*Bootstrap overrides*/
    .popover {
        max-width: fit-content;
    }
    .modal-content {
        border: none;
    }
    .form-control {
        border: 1px solid #F1F1F1;
        color: #000A0D!important;
    }
    .dropdown-menu {
        border-radius: 4px!important;
    }
    .progress {
        background-color: #F0F0F3;
        border-radius: 20px;
        height: 10px;
    }
    .progress-bar {
        background-color: #718D9A;
        border-radius: 20px;
    }

    /* MODAL OVERRIDES */
    .modal-header-content {
        font-size: 18px;
    }
    .modal-header {
        border-bottom: 2px solid #F1F1F1!important;
    }
    .create-category-modal, .delete-modal {
        width:450px;
        margin: auto;
    }

    /*INPUT OVERRIDES*/
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

`

export default GlobalStyles