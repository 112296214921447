import { SET_TOKEN } from '../actions/actions';

export default function auth(state = {token: ''}, action) {
    switch(action.type) {
        case SET_TOKEN:
            if(action.token) localStorage.setItem('token', action.token);
            return { token: action.token }
        default:
            return state;
    }
}
