import { SET_INFRONT_READY } from '../actions/actions';

export default function infront(state = {isReady: false}, action) {
    switch(action.type) {
        case SET_INFRONT_READY:
            return { isReady: true }
        default:
            return state;
    }
}
