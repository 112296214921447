import { useKeycloak } from '@react-keycloak/web';
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { setGlobal, useGlobal } from 'reactn';
import { InitializeToolkit } from './services/auth';
import { getBackendUrl } from './utils/utils';

setGlobal({
    scroll: 0,
    selectedItem: '',
    selectedParents: [],
    expanded: false,
    router: [],
    token: null,
    loginUrl: null,
    SDKDocumentation: null,
    allSdkRouteItems: null,
    globalWtkVersion: null,
    showSidebar: true,
    userCodeSnippetSidebarActions: null,
    showLoadingInSidebar: false,
    hasChangedSandboxValue: null,
    monacoInstances: [],
    showConsole: false,
    SDKVersion: null
});
export const InitProvider = (props) => {
    const { children } = props
    const [token, setToken] = useGlobal('token')
    const [loginUrl, setLoginUrl] = useGlobal('loginUrl')
    const { keycloak, initialized } = useKeycloak() 

    useEffect(() => {
        if(initialized) {
            InitializeToolkit(keycloak?.token, keycloak?.refreshToken)
            setToken({
                token: keycloak.token,
                refreshToken: keycloak.refreshToken
            })
            keycloak.onTokenExpired = () => {
                keycloak.logout()
            }
            fetch(getBackendUrl() + '/api/login-url').then(res => res.text()).then(data => setLoginUrl(data))
        }
    }, [initialized])
    return (
        <>
           {token && (
                <>        
                    {children}
                </>
            )}
{/*             {children} */}
        </>
    )
}