import { combineReducers } from 'redux'
import router from './router'
import auth from './authReducer'
import returnURL from './returnURLReducer'
import infront from './infrontReducer'
import chainFinder from './chainFinderReducer'

const rootReducer = combineReducers({
    router,
    auth,
    returnURL,
    infront,
    chainFinder
})

export default rootReducer;